import request from '@/utils/request';
// 用户列表
export function getRoleList(data) {
  return request('post', '/apm/role/findRoleList', data)
}
// 商户列表
export function getMerchantList(data) {
  return request('get', '/apm/merchant/mrListBySp', data)
}
// 新增和编辑角色
export function getRoleEdit(data) {
  return request('post', '/apm/role/mod', data)
}

// 删除角色
export function getRoleDel(data) {
  return request('post', '/apm/role/remove', data)
}